import React from 'react';
import { Link } from 'react-scroll';

const VehicleSection = () => {

  return (
    <section id="services" className="bg-black py-24">
      <div className="container mx-auto text-center">
        <h2 className="text-4xl underline font-bold text-teal-500 mb-8">Range of Bikes</h2>
        <p className="text-gray-300 text-2xl mb-12">
          We offer a variety of bikes for rent, including geared and non-geared options.
        </p>

        {/* Grid to display bikes */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
          {/* Geared Bike Card 1 */}
          <div className="p-6">
            <div className="border border-teal-500 rounded-lg shadow-lg hover:shadow-xl transition duration-300">
              <img
                src="/bullet.jpeg"
                alt="Royal Enfield Classic 350"
                className="w-full h-60 object-cover mb-4 rounded-t-lg transition-transform duration-300 hover:scale-105"
              />
              <h3 className="text-xl font-semibold mb-2 text-white">Royal Enfield Classic 350</h3>
              <p className="text-gray-300">Rs. 25/km & 150 km/day</p>
              <Link className="bg-teal-500 text-white font-semibold px-4 py-2 mt-4 inline-block" to="footer" smooth={true} duration={500}>
                Book Now
              </Link>
              <div className="flex justify-between mt-4 bg-teal-500 text-white p-2">
                <span>350cc</span>
                <span>Geared</span>
                <span>Yes</span>
              </div>
            </div>
          </div>

          {/* Geared Bike Card 2 */}
          <div className="p-6">
            <div className="border border-teal-500 rounded-lg shadow-lg hover:shadow-xl transition duration-300">
              <img
                src="/duke.jpeg"
                alt="KTM Duke 200"
                className="w-full h-60 object-cover mb-4 rounded-t-lg transition-transform duration-300 hover:scale-105"
              />
              <h3 className="text-xl font-semibold mb-2 text-white">KTM Duke 200</h3>
              <p className="text-gray-300">Rs. 20/km & 150 km/day</p>
              <Link className="bg-teal-500 text-white font-semibold px-4 py-2 mt-4 inline-block" to="footer" smooth={true} duration={500}>
                Book Now
              </Link>
              <div className="flex justify-between mt-4 bg-teal-500 text-white p-2">
                <span>200cc</span>
                <span>Geared</span>
                <span>Yes</span>
              </div>
            </div>
          </div>

          {/* Geared Bike Card 3 */}
          <div className="p-6">
            <div className="border border-teal-500 rounded-lg shadow-lg hover:shadow-xl transition duration-300">
              <img
                src="/ns.png"
                alt="Bajaj Pulsar 220"
                className="w-full h-60 object-cover mb-4 rounded-t-lg transition-transform duration-300 hover:scale-105"
              />
              <h3 className="text-xl font-semibold mb-2 text-white">Bajaj Pulsar 200NS</h3>
              <p className="text-gray-300">Rs. 18/km & 150 km/day</p>
              <Link className="bg-teal-500 text-white font-semibold px-4 py-2 mt-4 inline-block" to="footer" smooth={true} duration={500}>
                Book Now
              </Link>
              <div className="flex justify-between mt-4 bg-teal-500 text-white p-2">
                <span>220cc</span>
                <span>Geared</span>
                <span>Yes</span>
              </div>
            </div>
          </div>

          <div className="p-6">
            <div className="border border-teal-500 rounded-lg shadow-lg hover:shadow-xl transition duration-300">
              <img
                src="/cb350.png"
                alt="Royal Enfield Classic 350"
                className="w-full h-60 object-cover mb-4 rounded-t-lg transition-transform duration-300 hover:scale-105"
              />
              <h3 className="text-xl font-semibold mb-2 text-white">HONDA CB 350</h3>
              <p className="text-gray-300">Rs. 25/km & 150 km/day</p>
              <Link className="bg-teal-500 text-white font-semibold px-4 py-2 mt-4 inline-block" to="footer" smooth={true} duration={500}>
                Book Now
              </Link>
              <div className="flex justify-between mt-4 bg-teal-500 text-white p-2">
                <span>350cc</span>
                <span>Geared</span>
                <span>Yes</span>
              </div>
            </div>
          </div>

          <div className="p-6">
            <div className="border border-teal-500 rounded-lg shadow-lg hover:shadow-xl transition duration-300">
              <img
                src="/hunter.png"
                alt="Royal Enfield Classic 350"
                className="w-full h-60 object-cover mb-4 rounded-t-lg transition-transform duration-300 hover:scale-105"
              />
              <h3 className="text-xl font-semibold mb-2 text-white">Royal Enfield Hunter 350</h3>
              <p className="text-gray-300">Rs. 25/km & 150 km/day</p>
              <Link className="bg-teal-500 text-white font-semibold px-4 py-2 mt-4 inline-block" to="footer" smooth={true} duration={500}>
                Book Now
              </Link>
              <div className="flex justify-between mt-4 bg-teal-500 text-white p-2">
                <span>350cc</span>
                <span>Geared</span>
                <span>Yes</span>
              </div>
            </div>
          </div>

          <div className="p-6">
            <div className="border border-teal-500 rounded-lg shadow-lg hover:shadow-xl transition duration-300">
              <img
                src="/dominar.png"
                alt="Bajaj Pulsar 220"
                className="w-full h-60 object-cover mb-4 rounded-t-lg transition-transform duration-300 hover:scale-105"
              />
              <h3 className="text-xl font-semibold mb-2 text-white">Bajaj Dominar</h3>
              <p className="text-gray-300">Rs. 18/km & 150 km/day</p>
              <Link className="bg-teal-500 text-white font-semibold px-4 py-2 mt-4 inline-block" to="footer" smooth={true} duration={500}>
                Book Now
              </Link>
              <div className="flex justify-between mt-4 bg-teal-500 text-white p-2">
                <span>400cc</span>
                <span>Geared</span>
                <span>Yes</span>
              </div>
            </div>
          </div>

          <div className="p-6">
            <div className="border border-teal-500 rounded-lg shadow-lg hover:shadow-xl transition duration-300">
              <img
                src="/apache.jpeg"
                alt="KTM Duke 200"
                className="w-full h-60 object-cover mb-4 rounded-t-lg transition-transform duration-300 hover:scale-105"
              />
              <h3 className="text-xl font-semibold mb-2 text-white">TVS Apache</h3>
              <p className="text-gray-300">Rs. 20/km & 150 km/day</p>
              <Link className="bg-teal-500 text-white font-semibold px-4 py-2 mt-4 inline-block" to="footer" smooth={true} duration={500}>
                Book Now
              </Link>
              <div className="flex justify-between mt-4 bg-teal-500 text-white p-2">
                <span>180cc</span>
                <span>Geared</span>
                <span>Yes</span>
              </div>
            </div>
          </div>

          {/* Geared Bike Card 4 */}
          <div className="p-6">
            <div className="border border-teal-500 rounded-lg shadow-lg hover:shadow-xl transition duration-300">
              <img
                src="/mt15.png"
                alt="Yamaha FZ S"
                className="w-full h-60 object-cover mb-4 rounded-t-lg transition-transform duration-300 hover:scale-105"
              />
              <h3 className="text-xl font-semibold mb-2 text-white">Yamaha MT15</h3>
              <p className="text-gray-300">Rs. 18/km & 150 km/day</p>
              <Link className="bg-teal-500 text-white font-semibold px-4 py-2 mt-4 inline-block" to="footer" smooth={true} duration={500}>
                Book Now
              </Link>
              <div className="flex justify-between mt-4 bg-teal-500 text-white p-2">
                <span>150cc</span>
                <span>Geared</span>
                <span>Yes</span>
              </div>
            </div>
          </div>
        </div>

        

        {/* Non-Gear Vehicles Section */}
        <h2 className="text-4xl underline font-bold text-teal-500 mb-8 mt-12">Non-Gear Vehicles</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
          {/* Non-Gear Vehicle Card 1 */}
          <div className="p-6">
            <div className="border border-teal-500 rounded-lg shadow-lg hover:shadow-xl transition duration-300">
              <img
                src="/activa.png"
                alt="Honda Activa"
                className="w-full h-60 object-cover mb-4 rounded-t-lg transition-transform duration-300 hover:scale-105"
              />
              <h3 className="text-xl font-semibold mb-2 text-white">Honda Activa</h3>
              <p className="text-gray-300">Rs. 12/km & 100 km/day</p>
              <Link className="bg-teal-500 text-white font-semibold px-4 py-2 mt-4 inline-block" to="footer" smooth={true} duration={500}>
                Book Now
              </Link>
              <div className="flex justify-between mt-4 bg-teal-500 text-white p-2">
                <span>110cc</span>
                <span>Non-Geared</span>
                <span>Yes</span>
              </div>
            </div>
          </div>

          {/* Non-Gear Vehicle Card 2 */}
          <div className="p-6">
            <div className="border border-teal-500 rounded-lg shadow-lg hover:shadow-xl transition duration-300">
              <img
                src="/jupiter.jpeg"
                alt="TVS Jupiter"
                className="w-full h-60 object-cover mb-4 rounded-t-lg transition-transform duration-300 hover:scale-105"
              />
              <h3 className="text-xl font-semibold mb-2 text-white">TVS Jupiter</h3>
              <p className="text-gray-300">Rs. 12/km & 100 km/day</p>
              <Link className="bg-teal-500 text-white font-semibold px-4 py-2 mt-4 inline-block" to="footer" smooth={true} duration={500}>
                Book Now
              </Link>
              <div className="flex justify-between mt-4 bg-teal-500 text-white p-2">
                <span>110cc</span>
                <span>Non-Geared</span>
                <span>Yes</span>
              </div>
            </div>
          </div>

          {/* Non-Gear Vehicle Card 3 */}
          <div className="p-6">
            <div className="border border-teal-500 rounded-lg shadow-lg hover:shadow-xl transition duration-300">
              <img
                src="/ntorq.jpeg"
                alt="Scooty Pep+"
                className="w-full h-60 object-cover mb-4 rounded-t-lg transition-transform duration-300 hover:scale-105"
              />
              <h3 className="text-xl font-semibold mb-2 text-white">TVS Ntorq</h3>
              <p className="text-gray-300">Rs. 10/km & 100 km/day</p>
              <Link className="bg-teal-500 text-white font-semibold px-4 py-2 mt-4 inline-block" to="footer" smooth={true} duration={500}>
                Book Now
              </Link>
              <div className="flex justify-between mt-4 bg-teal-500 text-white p-2">
                <span>125cc</span>
                <span>Non-Geared</span>
                <span>Yes</span>
              </div>
            </div>
          </div>

          {/* Non-Gear Vehicle Card 4 */}
          <div className="p-6">
            <div className="border border-teal-500 rounded-lg shadow-lg hover:shadow-xl transition duration-300">
              <img
                src="/access1.png"
                alt="Vespa VXL 150"
                className="w-full h-60 object-cover mb-4 rounded-t-lg transition-transform duration-300 hover:scale-105"
              />
              <h3 className="text-xl font-semibold mb-2 text-white">Suzuki Access</h3>
              <p className="text-gray-300">Rs. 15/km & 100 km/day</p>
              <Link className="bg-teal-500 text-white font-semibold px-4 py-2 mt-4 inline-block" to="footer" smooth={true} duration={500}>
                Book Now
              </Link>
              <div className="flex justify-between mt-4 bg-teal-500 text-white p-2">
                <span>125cc</span>
                <span>Non-Geared</span>
                <span>Yes</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VehicleSection;
